.NewChatOnlinePageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 4px; */
  
  /* use dynamically height and width only on mobile */
  @media (max-width: 992px) {
    @supports (height: 100dvh) {
      width: 100dvw;
      height: 100dvh;
    }
  }
}

.MainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  gap: 2px;

  /* use dynamically height and width only on mobile */
  @media (max-width: 992px) {
    overflow: hidden;

    @supports (height: 100dvh) {
      width: 100dvw;
      height: 100dvh;
    }
  }
}

.ChatSelectButton {
  padding: 8px;
  border-radius: 8px;
  border-block: undefined;
  display: flex;
  height: 80px;

  /* @media (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 450px;
  } */
}

.ChatSelectButton:hover,
.ContactCardMessage:hover,
.ArchivedChatsButton:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.ChatSelectButtonSelected {
  background-color: #DDD;
}

.ChatSelectButtonSelected:hover {
  background-color: #DDD;
}

.ListItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 13px;
  height: 60px;
}

.ChatSelectButtonLeftContainer {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.ChatSelectButtonLeft {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChatSelectButtonAvatar {
  background-color: #B2B2B2;
  min-width: 50px;
  min-height: 50px;
}

.ListItemDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6E6E6E;
}

.ListItemTitleContainer {
  display: flex;
  flex-direction: column;
}

.ListItemTitleStrongContainer {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.ListItemTitleStrong {
  font-size: 15px;
  color: #333
}

.ListItemTitleTagsContainer {
  flex: 1;
  white-space: nowrap;
  overflow: hidden
}

.ListItemContentIconContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ListItemContentIcon {
  font-size: 1.1em;
}

.Width100 {
  width: 100%;
}

.ArchivedChatsButton {
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  color: #999;
  cursor: default;
}

.ArchivedChatsButtonDivider {
  margin: 8px 0px 8px 0px;
}

.ChatsListInner {
  flex: 1;
  overflow-y: hidden;
  padding: 10px;
  background-color: #FFF;
  border-radius: 4px;
  margin-top: 2px;
  /* box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, .1); */
}

.TitleDivider {
  display: flex;
  height: 80px;
}

.TitleDividerDivider {
  margin: 8px 0px
}

.TitleDividerText {
  color: #999;
  font-size: 20px;
}

.ChatWindowContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ChatWindowAlert {
  width: 100%;
  padding: 4px;
}

.MessageContainerFromClient {
  padding: 4px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.MessageContainerFromUser {
  padding: 4px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.GroupClientDataName {
  color: #1852ab;
}

.MessageForwardedHeaderDiv {
  position: relative;
  margin-bottom: 5px;
  /* margin-left: -5px; */

  font-style: oblique;
  font-size: 0.85em;
  color: gray;
}

.MessageBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  width: 100%;
  font-size: 11px;
}

.MessageBottomTemplateIcon {
  height: 16px;
  width: 16px;
  display: flex;
  color: #9c9c9c;
}

.MessageStatusIcon {
  height: 16px;
  width: 16px;
  display: flex;
}

.MessageDeletedIcon {
  color: #ad3c34;
  margin-left: 5px;
}

.MessageDropdownButton {
  position: absolute;
  right: 0;
  padding: 0px 8px 0px 8px;
  z-index: 1;
  background-color: inherit;
}

.MessageLineContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.MessageLineContainerFromClient {
  justify-content: flex-start;
}

@keyframes HighlightMessage {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: rgba(0, 0, 0, 0.06);
  }

  100% {
    background-color: transparent;
  }
}

.MessageLineContainerHighligh {
  animation-name: HighlightMessage;
  animation-duration: 1s;
  animation-iteration-count: 3;
}

.MessageInnerContainer {
  border-radius: 4px;
  max-width: 400px;
  width: fit-content;
  padding: 10px;
  background-color: #D9FDD3;
}

.MessageInnerContainerFromClient {
  background-color: #FFF;
}

.MessageInner {
  position: relative;
  background-color: inherit;
}

.DescriptionLabelContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.DescriptionLabelIcon {
  height: 16px;
  width: 16px;
  display: flex;
}

.QuotedMessage {
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  max-height: 100px;
  overflow-y: auto;
  border-left: 4px solid #6bcbef;
  color: #6bcbef;
}

.QuotedMessage:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.06);
}

.QuotedMessageSent {
  border-left: 4px solid #35cd96;
  color: #35cd96;
}

.MessageScheduledIcon {
  color: #2e9eee;
  margin-top: 5px;
  font-size: 12px;
  display: flex;
  gap: 4px;
}

.TextMessage {
  margin-bottom: -7px;
  overflow-wrap: anywhere;
  color: #000;
}

.VideoMessage,
.AudioMessage,
.ImageMessage {
  margin-bottom: 4px;
}

video,
audio {
  width: 360px;
}

.ContactCardMessage {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
}

.ContactCardMessageDivider {
  margin: 8px 0px;
}

.ContactCardMessageAvatar {
  background-color: #B2B2B2;
}

.ContactCardMessageAvatar:not(:first-child) {
  margin-inline-start: -25px;
}

.ContactModal {
  display: flex;
  flex-direction: column;
}

.ImageMessageImg {
  border-radius: 8px;
  max-width: 360px;
}

.ChatOnlineCard {
  padding: 10px;
  background-color: #FFF;
  /* border-radius: 8px; */
  /* margin: 4px; */
  box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, .1);
}

.ChatsFilter {
  border-radius: 4px;
}

.ChatInputBar {
  width: 100%;
  display: flex;
  /* min-height: 60px; */
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #F0F2F5;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 4px 4px;
}

.ChatInputBarQuotedMessage {
  width: 100%;
  display: flex;
}

.ChatInputBarContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.ChatInputBarTextArea {
  resize: none;
  border-radius: 8px;
}

.ChatMessagesList {
  flex: 1;
  overflow: auto;
  width: 100%;
  background-color: #DFD8CE;
  padding: 10px;
  /* border-radius: 8px; */
  box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, .1);
  /* margin: 2px; */

  @media (min-width: 992px) {
    margin-top: 2px;
    border-top-left-radius: 4px;
  }
}

.MoreMessagesLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 4px 0px;
}

.ChatTopBar {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  height: 60px;
  border-radius: 4px 4px 0px 0px;
  
  @media (min-width: 992px) {
    border-radius: 4px 4px 0px 4px;
  }
}

.ChatTopBarContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ChatTopBarAvatar {
  background-color: #B2B2B2;
  min-height: 35;
  min-width: 35;
}

.ChatTopBarClientData {
  display: flex;
  flex-direction: column;
}

.ClientTags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.AlertMessageContainer {
  margin: 2px;
  margin-right: 0px;
}

.AlertMessageAlert {
  border: none;
  box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
}

.TopBar {
  border-radius: 4px;
}

.TopBarInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

.DropFilesHere {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SelectClient {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SearchClientMessageItem {
  padding: 5px 10px !important;
}

.SearchClientMessageItem:hover {
  background-color: #DDD;
}
